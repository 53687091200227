.manager-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; 
    padding: 20px;
}

.manager-tabela-de-ramais{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.manager-tabela-de-ramais table{
    width:50%;
    border: 1px solid #000;
    border-collapse: collapse;
}
.manager-tabela-de-ramais table tr{
    border: 1px solid #000;
    
}
.manager-tabela-de-ramais table thead{
    border: 1px solid #000;
    background-color: rgba(255,255,255,0.5);
    font-weight: bold;
    font-size: 1.2rem;
}
.manager-tabela-de-ramais table tbody{
    border: 1px solid #000;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: rgba(0,0,0,0.1);
}
.manager-tabela-de-ramais table tr td{
    border: 1px solid #000;
    padding: 10px;

}

.manager-tabela-de-ramais table tr td input{
    border: none;
    outline: none;
}
.manager-tabela-de-ramais td{
    padding: 10px;
}


.edit-tela-de-ramais{
    display: flex;
    width: 100%;    
}


.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.edit-ramais-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    padding: 20px;
}
.icon-open-link{
    width: 20px;
    height: 20px;
    float: right;
    cursor: pointer;
}
.btn-exclude{
    color: rgb(114, 0, 0);
    margin-top: 5px;
    margin-left: 23%;
    cursor: pointer;
    font-size: 26px;
    transition: all .3s ease;
    border:none;
    background-color: inherit;
}
.btn-edit{
    cursor: pointer;
    font-size: 17px;
    background-color: #0b3157;
}
.btn-creation{
    color: rgb(13, 54, 95);
    cursor: pointer;
    font-size: 18px;
    transition: all .3s ease;
    border:none;
    background-color: inherit;
    background-color: rgb(70, 88, 248);
    margin-top: 15px;
    color: #fff;
    
}
.btn-primary{
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}


