.search-box-ucms {
    margin-top: 1.5rem;
    padding: 0 1rem;
}

.ucms-search-input{
    width: 91%;
    height: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 18px;
    margin-left: 6%;
    color: #000;
    outline: none;
}