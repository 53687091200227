
/* envolcro */
.edit-tela-de-ramais{
    display: flex;
    width: 100%;    
} 


.edit-ramais-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
/* envolcro */

/* titulo */
.edit-ramais-div-nome{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
}

.edit-ramais-div-nome label{
    font-size: 18px;
    margin: 10px 0;
}
.edit-ramais-div-nome input{
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
}

/* titulo */



.ramais-to-add-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    background-color: white;
    padding: 20px;
    margin: 10px 10px 20px 10%;
}
.ramais-to-add-header select{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    margin: 20px 0;
}
.ramais-to-add-header input{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
}
.div-input-add-all{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top:10px;
}
.div-input-add-all label{
    font-size: 18px;
    margin: 10px 0;
}
.div-input-add-all input{
    height: 22px;
    width: 22px;
    margin-left: 5%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    float: right;

}


/*lista de adicionar ramais */
.ramais-to-add-main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
}
.ramais-to-add-list{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 60%;
    max-height: 440px;
    overflow-y: scroll;
    margin-left: 10%;
}

.ramais-to-add-list-item{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border: 1px solid #ccc;
}
.ramais-to-add-list-item-header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}
.ramais-to-add-list-item-header label{
    font-size: 18px;
    margin: 10px 0;
}
.ramais-to-add-list-item-header input{
    height: 20px;
    width: 20px;    
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
}
.added-already{
    background-color: #e1ecf7;
}



/* MINHA LISTA  em tabela sendo editada */

.meu-link-de-ramais{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    margin-left: -15%;
}

.meu-link-de-ramais-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    background-color: white;
    padding: 10px;
    margin-bottom: 5%;
    
}
.meu-link-de-ramais-list-name{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.meu-link-de-ramais-header label{
    font-size: 18px;
}
.meu-link-de-ramais-header input{
    height: 30px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    margin: 15px 0;
    font-size: 16px;
}


.meu-link-de-ramais-table{
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;        
    max-height: 440px;
    overflow-y: scroll;
    padding: 15px;
    border-radius: 5px;
}
.meu-link-de-ramais-table tr td{
    border: 1px solid #000;
    padding: 0 10px ;
    font-size: 16px;
    text-align: center;
    border-collapse: collapse; 
}

.meu-link-de-ramais-table input[type=checkbox]{
    height: 20px;
    width: 20px;
    margin:5px;

}
.input-table{
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    margin: 0 -4%; 
    width: 106%;
}

/* MINHA LISTA  em tabela sendo editada */

/*BOTÕES */
.buttons-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 33%;
    padding: 20px;

}
.add-many-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    color: #fff;
    background-color: rgb(11, 28, 184);
    border-radius: 5px;
    border: 1px solid #ccc;

}
.remove-many-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    background-color: rgb(189, 5, 5);
    color: #fff;
    border-radius: 5px;
    border: 1px solid #ccc;
}
.btn-inactive{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    background-color: #ccc;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #ccc;
}


/* 
.ramais-to-add-table{
    width: 80%;
    height: 20%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    background-color: white;
}

.ramais-to-add-table tr{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    margin: 10px;
}

.ramais-to-add-table tr td{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
}

.ramais-to-add-table tr td input{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
}

.ramais-to-add-table th{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    background-color: #f5f5f5;
    width: 10%;
}

.ramais-to-add-table td{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    background-color: #f5f5f5;
}
.ramais-to-add{
    display: block;
    width: 100%;
    margin: 10px 0 0 100px;

}
.ramais-to-add select{
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
}

 */