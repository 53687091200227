.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 30, 60, 0.15);
}


.login-form {
  background: rgba(0,0,0,0.239);
  border: 1px solid #979797;
  border-radius: 1rem;
  width: 25rem;
  background: rgba(255, 255, 255, 0.2);
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 30px;
}
/**
  #7487ce);
  */
.login-header {
  background:rgba(0, 30, 60, 0.9); 
  /* linear-gradient(90deg,rgba(0, 30, 60, 0.9), #7487ce); */
  border-radius: 1rem 1rem 0 0;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7rem;
}

.login-header img {
  width: 17rem;
}

.form-content {
  padding: .5rem 2rem 1rem;
  font-size: 1.4rem;
  font-weight: 300;
}

.form-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-block: 1rem;
}

.form-content input {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: .5rem;
  color: #212529;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5;
  padding: .375rem .75rem;
  margin-bottom: 1rem;
}

.next-btn {
  cursor: pointer;
  background: rgba(0, 30, 60, 0.9);
  border-radius: .5rem;
  align-items: center;
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 80%;
  line-height: 1.5;
  font-weight: 300;
  padding: .375rem .75rem;
  text-shadow: .1rem .1rem #333;
  box-shadow: .1rem .1rem #333;
  font-size: 1.4rem;
}

.previous-btn {
  cursor: pointer;
  background: linear-gradient(90deg, #61605F, #DAD8D6);
  border-radius: .5rem;
  align-items: center;
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 80%;
  line-height: 1.5;
  font-weight: 300;
  padding: .375rem .75rem;
  text-shadow: .1rem .1rem #333;
  box-shadow: .1rem .1rem #333;
  font-size: 1.4rem;
}


.forgot-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}