@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background-image: url('https://static.wixstatic.com/media/d50ce2_60283921b6fe4dc5bea1bf506fce8e58~mv2.png/v1/fill/w_1200,h_1026,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/d50ce2_60283921b6fe4dc5bea1bf506fce8e58~mv2.png');
  background-repeat: repeat;
  background-size: cover;
  height: 100vh;
}
.inputs-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

#logo-prefeitura{
  display:none;
  width: 200px;
}