#queues-on-view{
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-left:4%;
}
.container{
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding-bottom: 2rem;
}
h1 {
  
  text-align: center;
}
.options{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.options-title{
  font-size: 20px;
  margin-bottom: 1rem;
}
.options-inputs{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  
}
.row-date{
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}
.options-inputs input{
  margin-right: 1rem;
}
.buttons-options{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.btn-opt{
  margin-right: 1rem;
  background-color: #343a40;
  color: #fff;
  border: none;
  padding: 0.5rem;
  width: 140px;
  height: 50px;
  border-radius: 5px;
  font-size: 18px;

}
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table-striped{
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-striped tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 1px solid #dee2e6;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}
.table-striped.table-bordered {
  border: 0;
}
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}
.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.thead-dark{
  background-color: #343a40;
  color: #fff;
}
.table td, .table th {
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  text-align: center;
}
.table thead th {
  border-bottom: 2px solid #dee2e6;
}
.table thead th {
  border: 0;
}
.table .border {
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
.table thead .filter {
  border: 0;
  display: flex; 
  align-items: center; 
  gap: 1rem;
  height: 100%;
  cursor: pointer;
}

table button{
  background-color: #343a40;
  color: #fff;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
.thead-light{
  background-color: #e9ecef;  
}
.even{
  background-color: #f5f5f5;
}
.odd{
  background-color: #fff;
}
.form-check-input{
  position: relative;
  margin-top: 0.3rem;
  left: 50%;
  width: 1.5rem;
  height: 1.5rem;
}
.search{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  width: 50%;
  font-size: 1.2rem;
  padding: 0.5rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  outline: none;
}
.search:focus{
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.download-button{
  background-color: #343a40;
  color: #fff;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 1rem;
}
.row-date{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.row-date label{
  margin-right: 1rem;
  width: 10%;
}
.row-date input{
  font-size: 1.2rem;
  padding: 0.5rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  outline: none;
}
.row-date input:focus{
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.row-date button{
  margin-left: 1rem;
  background-color: #343a40;
  color: #fff;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.quick-download{
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 1rem;
    background-color: #343a40;
    color: #fff;
    border: none;
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;

}

.back-button{
    position: fixed;
    top: 0;
    left:4%;
    margin: 1rem;
    background-color: #343a40;
    color: #fff;
    border: none;
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
}
.back-button button{
    background-color: #343a40;
    color: #fff;
    border: none;
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    
}
.agent-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin:0;
    width: 80vw;
}

.queues-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-left:4%;
    gap: 1rem;
}





.agent-resume{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    gap: 1rem;
    width: 80vw;
}
.agent-resume-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    gap: 1rem;
    width: 70vw;
}
.resume-table{
  width: 100vw;
}

.queue-details-header{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resumo-dos-detalhes{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grafico-simples-detalhes{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem;
}
/* MEMBERS component in quue */
.queue-members{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  gap: 1rem;
}
.queue-members-header-div{
  display: flex;
  align-items: center;
  justify-content: center;  
  gap: 1rem;
  margin-left: -13%;
}
.queue-members-list{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  gap: 1rem;
  cursor: pointer;
  width: 100%;	
  overflow-x: auto;
}
/* beatifull card member*/
.queue-member{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  gap: 1rem;	
  overflow-x: auto;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 1rem;
  margin: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  box-shadow: 0 0 13px 0.6rem rgba(131, 127, 70, 0.25);
  cursor:auto;  
  min-width: 400px;
}
.queue-member-title{
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding: 25px;
}
.members-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.agente-button{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #343a40;
  color: #fff;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  width: 100%;
}

/* MEMBERS */

.queue-summary{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  gap: 1rem;
  cursor: pointer;
}

.quick-download button{
  background-color: #343a40;
  color: #fff;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
}

.loading-filter{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);

}
.progress{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: -10;
}
.progress-bar{
  height: 8%;
  background-color: #343a40;
  color: #fff;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
.progress-bar-striped{
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  animation: progress-bar-stripes 1s linear infinite;
}
.progress-bar-striped-stats{
  background-image:linear-gradient( 45deg, #86adf1 25%, transparent 25%, transparent 50%, #83a0cc 50%, #a1beec     75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  animation: progress-bar-stripes-stats 1s linear infinite;
}
.progress-bar-striped-details{
  background-image:linear-gradient( 45deg, #86adf1 25%, transparent 25%, transparent 50%, #83a0cc 50%, #a1beec     75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  animation: progress-bar-stripes-stats 1s linear infinite;
}

.input-selection{
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;  
  font-size: 18px;
}



@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes-stats {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 1rem 0;
    }
}
	

.progress-bar-agents{
  position: fixed;
  top: 0;
  width: 100%;
  height:6%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
  font-size: 1.4rem;
  font-weight: 800;
  z-index: 1000;
  border-radius: 0.25rem;
  box-shadow: 0 0 13px 0.6rem rgba(131, 127, 70, 0.25);
 
}

/* progress-bar-agents */

.abandonada{
    background-color: #f8d7da !important;
    color: #721c24 !important;
}
.atendida{
    background-color: #d4edda !important;
    color: #155724 !important;
}
.icon-show-members{
  padding: 0.5rem;
  margin-top: 0.7rem;
}
.arrow-members{
  font-size: 2rem;
  cursor:pointer;
  padding:  0.1rem;
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  background-color: #343a40;  
}
