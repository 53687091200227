.ramais{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    margin-top: 20px;
    padding: 0 40px;
/*
    background-color: #f5f5f5; */
}
.ramais-header-org-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    height: 50px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
    padding: 0 20px;
}
.ramais-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 50px;
}
.ramais-header-picture-img{
    margin-top: 10vh;
    margin-left: 30px;
    width: 160px;
    border: 5px solid #f5f5f5;;
}

.ramais-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    padding: 0 40px;
    /* background-color: #f5f5f5; */
}

.ramais-body-table{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    padding: 0 40px;
    /* background-color: #f5f5f5; */
}

.ramais-body-table table{
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 40px;
    padding: 0 10px;
}
.ramais-body-table table thead {
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
}
.ramais-body-table table thead tr th{
    padding: 10px;
    border: 1px solid black;
    font-size:22px;
}

.ramais-body-table table tr{
    padding: 0 10px;
}

.ramais-body-table table tr td{
    padding: 5px 14px;
}
.ramais-body-table table tbody{
    padding: 0 10px;
    font-size: 20px;
}
.ramais-body-table table td{
    border: 1px solid black;
    border-collapse: collapse;
}

.ramais-body-table input{
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
}

.ramais-body-table select{
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
}


.ramais-body-table button{
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
}

.ramais-body-table-inputs{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 43%;
    height: 100%;
    margin-top: 20px;
    padding: 0 40px;
    /* background-color: #f5f5f5; */	
}
.ramais-body-table-inputs div{
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
    padding: 0 40px;
    /* background-color: #f5f5f5; */	
    font-size: 22px;
    
}
.ramais-body-table-inputs div label{
    width: 100%;
    height: 100%;
    padding: 0 40px;
    /* background-color: #f5f5f5; */	
    font-size: 22px;
    
}
.ramais-body-table-inputs select{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 18px;
}
.ramais-body-table .even{
    background-color: rgba(255, 255, 255, 0.3);
}
.ramais-body-table .odd{
    background-color: rgba(160, 154, 154, 0.3); 
}