.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  width: 40px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  margin: 0 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: rgb(0, 30, 60);;
  color: #eee;
  border-color: #eee;
}

.pagination button.active {
  font-weight: 900;
  border-color: #eee;
  background: linear-gradient(55deg, #61605F, #DAD8D6);
  color: #eee;  
}