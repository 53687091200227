.view-set{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.icon{
    margin-left: 10px;
    color: #343a40;
    margin-bottom: 21px;
}
.dashboard{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.queue-card{
    width: 33%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
}
.queue-card-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.queue-card-name{
    font-size: 20px;
    font-weight: 600;
}
.queue-card-number{
    font-size: 20px;
    font-weight: 600;
}
.queue-card-body{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; 
    align-items: center;
    margin-bottom: 10px;
}
.queue-card-graph{
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    padding: 20px;
    margin-bottom: 20px;
}
.queue-card-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.queue-checkbox{
    width: 2.2rem;
    height: 2.2rem;
}
.queue-main-details-button{
    width: 85%;
    height: 100%;
    background-color: #343a40;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-top: 10px;
}
.search-and-set{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.queue-details-body{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.quick-input-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

}
.quick-input-div label{
    font-size: 20px;
    font-weight: 600;
    margin-right: 10px;
}
.quick-input-div input{
    width: 2.2rem;
    height: 2.2rem;

}
