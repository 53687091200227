.buttons-files-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    cursor: pointer;
    

}
.button-file-div button{
    font-size: 22px;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
}
.button-file-pdf{
    color: black;
    /* background: linear-gradient(270deg, #f81717 10%, #f8b517 25%, #f8f817 40%, #17f817 55%, #17f8f8 70%, #1717f8 85%, #f817f8 100%); */
    /* fire doom gradient */
    background: linear-gradient(90deg, #7aa7fa 0%, rgb(167, 216, 248) 100%)
    
}
.button-file-excel{
    color: black;
    background: linear-gradient(90deg, #6acfcf 0%, #42fc42 100%);
   
}